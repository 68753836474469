import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import axios from 'axios';

import { BookGutsCoverGlobalStyles, Content, Subtitle, Title } from './BookGutsCover.styles';

interface RemoteData {
  title: string;
  subtitle?: string | null;
}

const TEST_DATA: RemoteData = {
  title: 'Alden Massonneau’s First Year',
  subtitle: 'As told by Alex & Sarah Massonneau',
};

export function BookGutsCover() {
  const [data, setData] = useState<RemoteData | null>(null);

  useEffect(() => {
    axios
      .get<RemoteData>('/__data__.json')
      .then(
        (response) => response.data,
        () => TEST_DATA,
      )
      .then(async (remoteData) => {
        setData(remoteData);
      });
  }, []);

  if (data === null) {
    return null;
  }

  return (
    <>
      <Helmet>
        <style>{BookGutsCoverGlobalStyles}</style>
      </Helmet>
      <Content>
        <Title>{data.title}</Title>
        {data.subtitle && <Subtitle>{data.subtitle}</Subtitle>}
      </Content>
    </>
  );
}
