import { styled } from '@linaria/react';

export const BookGutsCoverGlobalStyles = `
  @page {
    width: 8in;
    height: 10in;
    margin: 130px 170px;
  }

  body {
    print-color-adjust: exact !important;
    -webkit-print-color-adjust: exact !important;
    background: white;
  }
`;

export const Content = styled.div`
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 84.96pt;
`;

export const Title = styled.span`
  font-family: 'TimesRegular';
  text-align: center;
  font-size: 26.49pt;
  color: var(--surface-always-dark);
  white-space: pre-line;
  word-break: break-word;
`;

export const Subtitle = styled.span`
  font-family: 'TimesRegular';
  text-align: center;
  font-size: 13.24pt;
  color: var(--surface-always-dark);
`;
